<template>
  <v-navigation-drawer v-model="localDrawer" app floating color="background">
    <template v-slot:prepend>
      <v-btn
        active-class=""
        color="transparent"
        class="white--text ma-2"
        plain
        :to="{ name: 'dashboard' }"
        large
      >
        <v-img :src="require('@/assets/logo.png')" width="200px" contain></v-img>
      </v-btn>
    </template>
    <v-list dense rounded>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        active-class="white--text button-primary-gradient"
        exact
        :to="{ name: item.name }"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          title: this.$t("navigation.dashboardTitle"),
          icon: "mdi-view-dashboard",
          name: "dashboard"
        },
        { title: this.$t("navigation.newsTitle"), icon: "mdi-newspaper", name: "list-news" },
        {
          title: this.$t("navigation.productsTitle"),
          icon: "mdi-television",
          name: "list-products"
        },
        {
          title: this.$t("navigation.campaignsTitle"),
          icon: "mdi-cellphone-wireless",
          name: "list-campaigns"
        },
        {
          title: this.$t("navigation.rewardsTitle"),
          icon: "mdi-gift",
          name: "list-rewards"
        },
        {
          title: this.$t("navigation.usersTitle"),
          icon: "mdi-account",
          name: "list-users"
        },
        {
          title: this.$t("navigation.salesTitle"),
          icon: "mdi-sale",
          name: "list-sales"
        },
        {
          title: this.$t("navigation.purchasesTitle"),
          icon: "mdi-cart-outline",
          name: "list-purchases"
        }
      ]
    };
  },
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    _user() {
      return this.$auth.user() || {};
    },
    localDrawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit("admin-drawer-update", value);
      }
    }
  }
};
</script>
